@import '../../../vars';

.Container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    @media screen and (max-width: 991px) {
        margin-top: scaleVW(40, 768);
        margin-bottom: scaleVW(20, 768);
    }
    @media screen and (max-width: 767px) {
        margin-top: scaleVW(96, 375);
        margin-bottom: scaleVW(39, 375);
        width: 100%;
    }
    .InputContainer {
        display: flex;
        position: relative;
        width: max-content;
        @media screen and (max-width: 767px) {
            width: 100%;
            max-width: scaleVW(315, 375);
        }
        &[data-error="true"] input {
            border-bottom: 1px solid $c3;
        }
    }
    label {
        cursor: none;
        color: $c5;
        margin-bottom: scaleVW(7, 1440);
        @media screen and (max-width: 991px) {
            margin-bottom: scaleVW(7, 768);
        }
        @media screen and (max-width: 767px) {
            margin-bottom: scaleVW(7, 375);
        }
    }
    input {
        // border: 1px solid $c2;
        border: none;
        border-bottom: 1px solid $c2;
        color: $c2;
        background: transparent;
        // padding: scaleVW(7.7, 1440) scaleVW(30, 1440) scaleVW(7.7, 1440) scaleVW(10, 1440);
        padding: scaleVW(7.7, 1440) 0 scaleVW(7.7, 1440) 0;
        width: scaleVW(315, 1440);
        box-sizing: border-box;
        // border-radius: scaleVW(19, 1440);
        border-radius: 0;
        line-height: 1;
        transition: .5s;
        outline: none;
        -webkit-appearance: none !important;
        -webkit-border-radius: 0 !important;
        -webkit-appearance: textfield;
        &:-webkit-autofill,
        &:-webkit-autofill:hover, 
        &:-webkit-autofill:focus, 
        &:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px $c1 inset !important;
            -webkit-background-clip: text;
            -webkit-text-fill-color: $c2;
        }
        @media screen and (max-width: 991px) {
            // padding: scaleVW(7.7, 768) scaleVW(30, 768) scaleVW(7.7, 768) scaleVW(10, 768);
            padding: scaleVW(7.7, 768) 0 scaleVW(7.7, 768) 0;
            width: scaleVW(315, 768);
            border-radius: scaleVW(19, 768);
        }
        @media screen and (max-width: 767px) {
            // padding: scaleVW(7.7, 375) scaleVW(30, 375) scaleVW(7.7, 375) scaleVW(10, 375);
            padding: scaleVW(7.7, 375) 0 scaleVW(7.7, 375) 0;
            width: 100%;
            border-radius: scaleVW(19, 375);
        }
        &::placeholder {
            opacity: 1;
            color: $c2;
        }
    }
    button {
        background: none;
        border: none;
        outline: none;
        position: absolute;
        top: 50%;
        // right: scaleVW(10, 1440);
        right: 0;
        transform: translateY(-50%);
        color: $c2;
        cursor: none;
        transition: .2s;
        -webkit-appearance: none !important;
        -webkit-border-radius: 0 !important;
        @media screen and (max-width: 991px) {
            // right: scaleVW(10, 768);
        }
        &:disabled {
            opacity: 0;
            visibility: hidden;
            + .Spinner {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .Thanks {
        position: absolute;
        bottom: scaleVW(-5, 1440);
        left: 0;
        color: $c2;
        font-size: scaleVW(14, 1440);
        transform: translateY(100%);
        @media screen and (max-width: 991px) {
            font-size: scaleVW(14, 768);
            bottom: scaleVW(-5, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(13, 375);
            bottom: scaleVW(-5, 375);
        }
    }
    .Spinner {
        width: scaleVW(20, 1440);
        height: scaleVW(20, 1440);
        position: absolute;
        top: 50%;
        right: scaleVW(10, 1440);
        transform: translateY(-50%);
        transition: .2s;
        opacity: 0;
        visibility: hidden;
        @media screen and (max-width: 991px) {
            width: scaleVW(20, 768);
            height: scaleVW(20, 768);
            right: scaleVW(10, 768);
        }
        &:before {
            content: "";
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: scaleVW(16, 1440);
            height: scaleVW(16, 1440);
            margin-top: scaleVW(-8, 1440);
            margin-left: scaleVW(-8, 1440);
            border-radius: 50%;
            border: 2px solid #ccc;
            border-top-color: #333;
            animation: spin 0.8s infinite linear;
            @media screen and (max-width: 991px) {
                width: scaleVW(16, 768);
                height: scaleVW(16, 768);
                margin-top: scaleVW(-8, 768);
                margin-left: scaleVW(-8, 768);
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}