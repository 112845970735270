@import '../../vars';

.Container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 30;
    background-color: $c2;
    padding: scaleVW(35, 1440) scaleVW(25, 1440);
    box-sizing: border-box;
    transition: 3s ease-in-out;
    @media screen and (max-width: 991px) {
        padding: scaleVW(35, 768) scaleVW(25, 768);
    }
    @media screen and (max-width: 767px) {
        padding: scaleVW(35, 375) scaleVW(25, 375);
    }
    &[data-finished="true"] {
        transform: translateX(-100%);
    }
    .Percentage {
        font-size: scaleVW(195, 1440);
        line-height: 1;
        display: inline-flex;
        align-items: flex-start;
        @media screen and (max-width: 991px) {
            font-size: scaleVW(195, 768);
        }
        span {
            position: relative;
            top: scaleVW(15, 1440);
            font-size: scaleVW(35, 1440);
            line-height: 1;
            @media screen and (max-width: 991px) {
                top: scaleVW(15, 768);
                font-size: scaleVW(35, 768);
            }
        }
    }
}