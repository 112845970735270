@import '../../../vars';

.Container {
    display: flex;
    flex-wrap: wrap;
    .Title {
        width: 100%;
        margin-bottom: scaleVW(24, 1440);
        @media screen and (max-width: 991px) {
            margin-bottom: scaleVW(26, 768);
        }
        @media screen and (max-width: 767px) {
            margin-bottom: scaleVW(26, 375);
        }
    }
    .Col1 {
        width: 25.62%;
        @media screen and (max-width: 991px) {
            width: 50%;
        }
        @media screen and (max-width: 767px) {
            width: 100%;
        }
    }
    .Col2 {
        width: 25.62%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 991px) {
            width: 50%;
        }
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    .Col3 {
        width: 25.62%;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-top: scaleVW(42, 768);
        }
        @media screen and (max-width: 767px) {
            margin-top: scaleVW(42, 375);
        }
    }
    .Col4 {
        width: 23.14%;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-top: scaleVW(42, 768);
            flex-direction: row;
            flex-wrap: wrap;
            a {
                display: inline-block;
                &:first-of-type {
                    margin-right: scaleVW(84, 768);
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin-top: scaleVW(42, 375);
            a:first-of-type {
                margin-right: scaleVW(84, 375);
            }
        }
    }
    .Address {
        margin-bottom: scaleVW(18, 1440);
        @media screen and (max-width: 991px) {
            margin-bottom: scaleVW(18, 768);
        }
        @media screen and (max-width: 767px) {
            margin-bottom: scaleVW(18, 375);
        }
    }
    .Copyright {
        color: $c5;
        margin-top: auto;
        width: 100%;
        @media screen and (max-width: 991px) {
            margin-top: scaleVW(25, 768);
        }
        @media screen and (max-width: 767px) {
            margin-top: scaleVW(25, 375);
        }
    }
    a {
        display: table;
        text-decoration: none;
        color: $c2;
        transition: .5s;
        @media screen and (min-width: 1025px) {
            &:hover {
                color: $c4;
            }
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(14, 375);
        }
    }
}