@import '../../vars';

.Container {
    padding: scaleVW(150, 1440) 0;

    @media screen and (max-width: 991px) {
        padding: scaleVW(150, 768) 0;
    }
    @media screen and (max-width: 767px) {
        padding: scaleVW(80, 375) 0 scaleVW(40, 375) 0;
    }

    .Content {
        position: relative;
    }
    h1 {
        margin: 0;
        text-align: center;
        font-size: scaleVW(46, 1440);
        line-height: 1;
        font-weight: normal;
        @media screen and (min-width: 1600px) {
            font-size: 51.11px;
        }
        @media screen and (max-width: 991px) {
            font-size: scaleVW(38, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(23, 375);
        }
    }
    .Subtitle {
        font-size: scaleVW(23, 1440);
        line-height: 1;
        margin-top: scaleVW(30, 1440);
        text-align: center;
        @media screen and (min-width: 1600px) {
            font-size: 25.55px;
        }
        @media screen and (max-width: 991px) {
            font-size: scaleVW(23, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(18, 375);
        }
    }
    .Link {
        position: absolute;
        top: 0;
        left: 0;
        @media screen and (max-width: 991px) {
            // display: none;
            position: relative;
            margin-bottom: scaleVW(40, 768);
        }
        @media screen and (max-width: 767px) {
            margin-bottom: scaleVW(40, 375);
        }
        span {
            font-size: scaleVW(18, 1440);
            @media screen and (min-width: 1600px) {
                font-size: 20px;
            }
            @media screen and (max-width: 991px) {
                font-size: scaleVW(18, 768);
            }
            @media screen and (max-width: 767px) {
                font-size: scaleVW(18, 375);
            }
        }
    }
}