@import '../../_vars.scss';

.Container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  &[data-scroll-sticky-1="true"] {
    position: fixed;
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
    background-color: $c2;
    &[data-scroll-sticky-2="true"] {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
      transition: transform .5s;
    }
  }
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: scaleVW(27, 1440) 0;
    @media screen and (max-width: 991px) {
      padding: scaleVW(27, 768) 0;
      align-items: flex-start;
      &[data-burger="true"] {
        .MobileMenu {
          transform: translateY(0);
          > div {
            opacity: 1;
            visibility: visible;
            transition-delay: 1s;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      padding: scaleVW(20, 375) 0;
    }
    &[data-white="true"] {
      @media screen and (max-width: 991px) {
        &[data-burger="true"] {
          .Logo svg path {
            fill: $c1;
          }
        }
      }
      .Logo svg path {
        fill: $c2;
      }
      a {
        color: $c2;
        @media screen and (max-width: 991px) {
          color: $c1;
        }
      }
    }
    .Logo {
      width: scaleVW(180, 1440);
      z-index: 2;
      @media screen and (max-width: 991px) {
        width: scaleVW(180, 768);
      }
      @media screen and (max-width: 767px) {
        width: scaleVW(161, 375);
      }
      a {
        display: flex;
      }
      svg {
        width: 100%;
        height: auto;
        @media screen and (max-width: 767px) {
          height: scaleVW(15, 375);
        }
        path {
          fill: $c1;
          transition: .5s;
        }
      }
    }
    .LangSwitcher {
      width: scaleVW(180, 1440);
      text-align: right;
      @media screen and (max-width: 991px) {
        display: none;
        width: auto;
        a {
          display: inline-block;
          margin-right: scaleVW(40, 768);
        }
      }
      @media screen and (max-width: 767px) {
        a {
          margin-right: scaleVW(40, 375);
        }
      }
    }
    .Items {
      @media screen and (max-width: 991px) {
        display: none;
        a {
          margin-bottom: scaleVW(25, 768);
        }
      }
    }
    a {
      display: inline-block;
      margin-right: scaleVW(40, 1440);
      text-transform: uppercase;
      text-decoration: none;
      color: $c1;
      font-size: scaleVW(18, 1440);
      line-height: 1;
      transition: .5s;
      @media screen and (min-width: 1600px) {
        font-size: 20px;
      }
      @media screen and (min-width: 1025px) { 
        &:hover {
          color: $c4;
        }
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(18, 768);
        margin-right: 0;
        display: table;
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(23, 375);
      }
      &.Active {
        color: $c4;
      }
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
  .HamburgerContainer {
    display: none;
    @media screen and (max-width: 991px) {
      display: inline-block;
    }
  }
  .MobileMenu {
    display: none;
    @media screen and (max-width: 991px) {
      display: flex;
      position: fixed;
      flex-direction: column;
      align-items: flex-start;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $c2;
      z-index: 1;
      box-sizing: border-box;
      transform: translateY(-101%);
      transition: transform 1s;
      padding: 
        scaleVW(120, 768)
        scaleVW(30, 768)
        scaleVW(60, 768)
        scaleVW(30, 768);
      > div {
        opacity: 0;
        visibility: hidden;
        transition: 1s;
        transition-delay: 0s;
      }
      .Items {
        display: block;
      }
      .LangSwitcher {
        display: block;
        margin-top: auto;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 
        scaleVW(80, 375)
        scaleVW(21, 375)
        scaleVW(30, 375)
        scaleVW(21, 375);
      .LangSwitcher {
        display: block;
        margin-top: scaleVW(80, 375);
        a {
          font-size: scaleVW(18, 375);
        }
      }
    }
  }
  .Extra {
    display: flex;
    margin-top: scaleVW(140, 768);
    @media screen and (max-width: 767px) {
      margin-top: auto;
    }
    a {
      text-transform: capitalize;
      @media screen and (max-width: 767px) {
        font-size: scaleVW(18, 375);
      }
    }
    .Col1 {
      margin-right: scaleVW(64, 768);
      a {
        margin-bottom: scaleVW(30, 768);
      }
      @media screen and (max-width: 767px) {
        margin-right: scaleVW(64, 375);
        a {
          margin-bottom: scaleVW(30, 375);
        } 
      }
    }
    .Col2 {
      a {
        margin-bottom: scaleVW(6, 768);
        @media screen and (max-width: 767px) {
          margin-bottom: scaleVW(6, 375);
        }
      }
    }
  }
}
