@import "../../vars";

.Link{
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    transition: 0.5s;

    &[data-white="true"]{
        span{
            color: $c2;
        }

        svg path{
            fill: $c2;
        }
    }


    @media screen and (min-width: 1025px) {
        &:hover{
            opacity: 0.5;
        }
    }

    svg{

        width: scaleVW(20, 1440);
        height: auto;
        @media screen and (max-width: 991px) {
            width: scaleVW(20, 768);
            height: auto;
        }
        @media screen and (max-width: 767px) {
            width: scaleVW(14, 375);
            height: auto;
        }
    }

    span{

        font-size: scaleVW(23, 1440);
        color: $c1;
        // padding-left: scaleVW(5, 1440);
        @media screen and (min-width: 1600px) {
            font-size: 25.55px;
        }
        @media screen and (max-width: 991px) {
            font-size: scaleVW(23, 768);
            color: $c1;
            // padding-left: scaleVW(5, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(18, 375);
            color: $c1;
            // padding-left: scaleVW(5, 375);
        }
    }
}