@import '../../vars';

.Container {
  padding: scaleVW(150, 1440) 0 scaleVW(160, 1440) 0;
  box-sizing: border-box;
  @media screen and (max-width: 991px) {
    padding: scaleVW(100, 768) 0 scaleVW(160, 768) 0; 
  }
  @media screen and (max-width: 767px) {
    padding: scaleVW(80, 375) 0 scaleVW(100, 375) 0; 
  }
  .Link {
    span {
      font-size: scaleVW(18, 1440);
      @media screen and (min-width: 1600px) {
        font-size: 20px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(18, 768);
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(18, 375);
      }
    }
  }
  .Title {
    font-size: scaleVW(46, 1440);
    margin-bottom: scaleVW(42, 1440);
    // margin-top: scaleVW(40, 1440);
    margin-top: scaleVW(16, 1440);
    @media screen and (min-width: 1600px) {
      font-size: 51.11px;
    }
    @media screen and (max-width: 991px) {
      font-size: scaleVW(46, 768);
      margin-bottom: scaleVW(20, 768);
      // margin-top: scaleVW(40, 768);
      margin-top: scaleVW(16, 1440);
    }
    @media screen and (max-width: 767px) {
      font-size: scaleVW(23, 375);
      margin-bottom: scaleVW(15, 375);
      margin-top: scaleVW(34, 375);
    }
  }
  .Description{
    font-size: scaleVW(23, 1440);
    max-width: scaleVW(666, 1440);
    @media screen and (min-width: 1600px) {
      font-size: 25.55px;
    }
    @media screen and (max-width: 991px) {
      max-width: 100%;
      font-size: scaleVW(23, 768);
    }
    @media screen and (max-width: 991px) {
      font-size: scaleVW(18, 375);
    }
  }
  .Requirements{
    display: flex;
    padding-top: scaleVW(110, 1440);
    @media screen and (max-width: 767px) {
      padding-top: scaleVW(35, 375);
      flex-wrap: wrap;
      font-size: scaleVW(14, 375);
    }

    .Col1{
      //1370
      width: 25.76%;
      @media screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: scaleVW(35, 375);
      }
    }

    .Col2{
      width: calc(100% - 25.76%);
      margin: 0;
      max-width: scaleVW(542, 1440);
      padding-left: 1em;
      @media screen and (max-width: 991px) {
        max-width: 100%;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
  .DropZone {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: scaleVW(36, 1440);
    @media screen and (max-width: 991px) {
      margin-bottom: scaleVW(36, 768);
    }
    @media screen and (max-width: 767px) {
      margin-bottom: scaleVW(30, 375);
    }
    > div {
      overflow: hidden;
      font-size: scaleVW(23, 1440);
      line-height: 1;
      white-space: nowrap;
      // cursor: pointer;
      @media screen and (min-width: 1600px) {
        font-size: 25.55px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(23, 768);
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(18, 375);
      }
      &:nth-of-type(1){
        width: 52.63%;
        @media screen and (max-width: 991px) {
          width: 42%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          order: 1;
        }
      }
      &:nth-of-type(2) {
        width: 47.37%;
        text-decoration: underline;
        @media screen and (max-width: 991px) {
          width: 58%;
        }
        @media screen and (max-width: 767px) {
          width: 100%;
          order: 3;
        }
      }
      &:nth-of-type(3) {
        width: 100%;
        font-size: scaleVW(18, 1440);
        color: $c4;
        margin-top: scaleVW(5, 1440);
        @media screen and (min-width: 1600px) {
          font-size: 20px;
        }
        @media screen and (max-width: 991px) {
          font-size: scaleVW(18, 768);
          margin-top: scaleVW(5, 768);
        }
        @media screen and (max-width: 767px) {
          order: 2;
          font-size: scaleVW(14, 364);
          margin-top: scaleVW(10, 375);
          margin-bottom: scaleVW(20, 375);
        }
      }
    }
  }
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: scaleVW(100, 1440);
    @media screen and (max-width: 991px) {
      padding-top: scaleVW(100, 768);
    }
    @media screen and (max-width: 767px) {
      padding-top: scaleVW(96, 375);
    }
    div {
      outline: none;
    }
    h2 {
      width: 100%;
      font-weight: normal;
      font-size: scaleVW(46, 1440);
      line-height: 1;
      margin-top: 0;
      margin-bottom: scaleVW(55, 1440);
      @media screen and (min-width: 1600px) {
        font-size: 51.11px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(46, 768);
        margin-bottom: scaleVW(55, 768);
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(23, 375);
        margin-bottom: scaleVW(32, 375);
      }
    }
    .Col {
      width: 48.6%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    input, textarea {
      background-color: transparent;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid $c6;
      padding: 0;
      padding-bottom: scaleVW(10, 1440);
      outline: none;
      font-size: scaleVW(23, 1440);
      line-height: 1;
      width: 100%;
      color: $c1;
      margin-bottom: scaleVW(26, 1440);
      transition: .5s;
      @media screen and (min-width: 1600px) {
        font-size: 25.55px;
      }
      @media screen and (max-width: 991px) {
        padding-bottom: scaleVW(10, 768);
        font-size: scaleVW(23, 768);
        margin-bottom: scaleVW(26, 768);
      }
      @media screen and (max-width: 767px) {
        padding-bottom: scaleVW(16, 375);
        font-size: scaleVW(18, 375);
        margin-bottom: scaleVW(25, 375);
      }
      &[data-error="true"] {
        border-bottom: 1px solid $c3;
      }
      &::placeholder {
        color: $c1;
        opacity: 1;
      }
    }
    textarea {
      height: scaleVW(73, 1440);
      resize: none;
      @media screen and (max-width: 991px) {
        height: scaleVW(73, 768);
      }
      @media screen and (max-width: 767px) {
        height: scaleVW(78, 375);
      }
    }
    button {
      background-color: transparent;
      border-radius: 0;
      border: none;
      padding: 0;
      margin: 0;
      font-size: scaleVW(23, 1440);
      line-height: 1;
      // cursor: pointer;
      transition: .5s;
      @media screen and (min-width: 1600px) {
        font-size: 25.55px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(23, 768);
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(18, 375);
      }
      &:hover {
        opacity: .5;
      }
    }
  }
  .Success {
    display: flex;
    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
    }
    .Title {
      font-size: scaleVW(46, 1440);
      line-height: 1;
      max-width: scaleVW(568, 1440);
      margin-bottom: scaleVW(40, 1440);
      @media screen and (min-width: 1600px) {
        font-size: 51.11px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(46, 768);
        margin-bottom: scaleVW(40, 768);
        max-width: 100%;
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(23, 375);
        margin-bottom: scaleVW(30, 375);
      }
    }
    .Text {
      font-size: scaleVW(23, 1440);
      line-height: LH(32, 23);
      max-width: scaleVW(549, 1440);
      margin-bottom: scaleVW(40, 1440);
      @media screen and (min-width: 1600px) {
        font-size: 25.55px;
      }
      @media screen and (max-width: 991px) {
        font-size: scaleVW(23, 768);
        max-width: 100%;
        margin-bottom: scaleVW(40, 768);
      }
      @media screen and (max-width: 767px) {
        font-size: scaleVW(14, 375);
        margin-bottom: scaleVW(40, 375);
      }
    }
    .Link {
      span {
        font-size: scaleVW(18, 1440);
        @media screen and (min-width: 1600px) {
          font-size: 20px;
        }
        @media screen and (max-width: 991px) {
          font-size: scaleVW(18, 768);
        }
        @media screen and (max-width: 767px) {
          font-size: scaleVW(14, 375);
        }
      }
      svg {
        width: scaleVW(14, 1440);
      }
    }
    .Col1 {
      width: 51.24%;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    .Col2 {
      width: 48.76%;
      @media screen and (max-width: 991px) {
        display: none;
      }
      img {
        width: scaleVW(549, 1440);
        height: auto;
        position: relative;
        // top: scaleVW(-44, 1440);
      }
    }
  }
}
