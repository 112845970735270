@import '../../../vars';

.Container {
    font-size: scaleVW(46, 1440);
    letter-spacing: -0.04em;
    line-height: 1;
    text-transform: capitalize;
    .MobileBr {
        display: none;
    }
    @media screen and (min-width: 1600px) {
        font-size: 51.11px;
    }
    @media screen and (max-width: 991px) {
        font-size: scaleVW(46, 768);
        .MobileBr {
            display: block;
        }
    }
    @media screen and (max-width: 767px) {
        font-size: scaleVW(23, 375);
        line-height: LH(26, 23);
    }
    span {
        text-transform: lowercase;
    }
}