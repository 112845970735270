// Fonts
$f1: 'Plain';

// Colors
$c1: #000000;
$c2: #FFFFFF;
$c3: #F23D3D;
$c4: #B7B7B7;
$c5: #7F807F;
$c6: #EBEBEB;
$c7: #f9faf9;
$c8: #707070;
$c9: #FAFAFA;
$c10: #bebebe;

// Functions and mixins
@function scaleVW($size, $viewportWidth) {
    @return calc(($size * 100) / $viewportWidth) * 1vw;
}
@function LH($lineheight, $fontSize) {
    @return calc($lineheight / $fontSize);
}
@mixin image-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
