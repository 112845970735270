@import '../../vars';

.Container {
    padding: scaleVW(105, 1440) 0;
    @media screen and (max-width: 991px) {
        padding: scaleVW(105, 768) 0;
    }
    @media screen and (max-width: 767px) {
        padding: scaleVW(80, 375) 0;
    }
    h2, p {
        margin: 0;
    }
    h2 {
        font-weight: normal;
        font-size: scaleVW(46, 1440);
        line-height: 1;
        margin-bottom: scaleVW(36, 1440);
        @media screen and (min-width: 1600px) {
            font-size: 51.11px;
        }
        @media screen and (max-width: 991px) {
            font-size: scaleVW(46, 768);
            margin-bottom: scaleVW(36, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(46, 375);
            margin-bottom: scaleVW(36, 375);
        }
    }
    p {
        font-weight: normal;
        font-size: scaleVW(23, 1440);
        line-height: LH(32, 23);
        margin-bottom: scaleVW(34, 1440);
        @media screen and (min-width: 1600px) {
            font-size: 25.55px;
        }
        @media screen and (max-width: 991px) {
            font-size: scaleVW(23, 768);
            margin-bottom: scaleVW(34, 768);
        }
        @media screen and (max-width: 767px) {
            font-size: scaleVW(18, 375);
            margin-bottom: scaleVW(34, 375);
        }
    }
}