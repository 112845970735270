@import '../../../vars';

.Hamburger {
    position:relative;
    width: scaleVW(36, 768);
    height: scaleVW(18, 768);
    margin: auto;
    // cursor: pointer;
    z-index: 10;
    @media screen and (max-width: 767px) {
        width: scaleVW(36, 375);
        height: scaleVW(18, 375);
    }
    span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $c4;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
        &:nth-child(1) {
            top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
            top: calc(50% - 1px);
        }
        &:nth-child(4) {
            bottom: 0px;
        }
    }
    &.Open {
        span {
            &:nth-child(1) {
                top: 50%;
                width: 0%;
                left: 50%;
            }
            &:nth-child(2) {
                -webkit-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                bottom: 50%;
                width: 0%;
                left: 50%;
            }
        }
    }
}