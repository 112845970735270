// Fonts
@font-face {
  font-family: 'Plain';
  src: url('./fonts/Plain-Regular.woff2') format('woff2'),
      url('./fonts/Plain-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// Vars
@import './vars';

// Packages
@import '~normalize.css';

// Default
* {
  -webkit-font-smoothing: antialiased !important;
}

input, textarea, span, a {
  cursor: none;
}

div[id="root"] {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  font-family: $f1;
  font-weight: normal;
  font-size: scaleVW(18, 1440);
  line-height: LH(24, 18);
  cursor: none !important;
  @media screen and (min-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: scaleVW(18, 768);
  }
  @media screen and (max-width: 767px) {
    font-size: scaleVW(18, 375);
  }
  &.mouse-hidden .custom-cursor {
    display: none !important;
  }
}

main {
  width: 100%;
  overflow: hidden;
}

.container{
  padding: 0 scaleVW(35, 1440);
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 991px) {
    padding: 0 scaleVW(30, 768);
  }
  @media screen and (max-width: 767px) {
    padding: 0 scaleVW(21, 375);
  }
}

.animated {
  transition: .5s;
}