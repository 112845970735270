@import '../../_vars.scss';

.Container {
  padding-top: scaleVW(80, 1440);
  margin-bottom: scaleVW(160, 1440);
  @media screen and (max-width: 991px) { 
    padding-top: scaleVW(100, 768);
    margin-bottom:  scaleVW(100, 768);
  }
  @media screen and (max-width: 767px) { 
    padding-top: scaleVW(80, 375);
    margin-bottom:  scaleVW(40, 375);
  }
  .Media {
    text-align: right;
    margin-bottom: scaleVW(25, 1440);
    @media screen and (max-width: 991px) { 
      display: none;
    }
    a {
      text-decoration: none;
      color: $c1;
      transition: .5s;
      &:hover {
        opacity: .5;
      }
    }
  }
  .Link {
    margin-bottom: scaleVW(60, 1440);
    @media screen and (max-width: 991px) { 
      margin-bottom: scaleVW(60, 768);
    }
    @media screen and (max-width: 767px) { 
      margin-bottom: scaleVW(40, 375);
    }
  }
  h1 {
    text-align: center;
    max-width: scaleVW(1134, 1440);
    margin: 0 auto;
    font-size: scaleVW(46, 1440);
    font-weight: normal;
    line-height: 1;
    margin-bottom: scaleVW(20, 1440);
    @media screen and (min-width: 1600px) {
      font-size: 51.11px;
    }
    @media screen and (max-width: 991px) { 
      font-size: scaleVW(46, 768);
      max-width: 100%;
      margin-bottom: scaleVW(20, 768);
    }
    @media screen and (max-width: 767px) { 
      font-size: scaleVW(23, 375);
      line-height: LH(26, 23);
      margin-bottom: scaleVW(20, 375);
    }
  }
  .Date {
    text-transform: capitalize;
    font-size: scaleVW(23, 1440);
    line-height: 1;
    text-align: center;
    color: $c4;
    margin-bottom: scaleVW(20, 1440);
    @media screen and (min-width: 1600px) {
      font-size: 25.55px;
    }
    @media screen and (max-width: 991px) { 
      font-size: scaleVW(23, 768);
      margin-bottom: scaleVW(20, 768);
    }
    @media screen and (max-width: 767px) { 
      font-size: scaleVW(18, 375);
      margin-bottom: scaleVW(20, 375);
    }
  }
  .Desc {
    text-align: center;
    font-size: scaleVW(33, 1440);
    line-height: LH(36, 33);
    max-width: scaleVW(666, 1440);
    margin: 0 auto;
    @media screen and (max-width: 991px) { 
      font-size: scaleVW(33, 768);
      max-width: 100%;
    }
    @media screen and (max-width: 767px) { 
      font-size: scaleVW(18, 375);
      line-height: LH(26, 18);
    }
  }
}
