@import '../../vars';

.Container {
    color: $c2;
    background-color: $c1;
    padding: scaleVW(40, 1440) 0 scaleVW(50, 1440) 0;
    margin-top: auto;
    @media screen and (max-width: 991px) {
        padding: scaleVW(40, 768) 0 scaleVW(50, 768) 0;
    }
    @media screen and (max-width: 767px) {
        padding: scaleVW(40, 375) 0;
    }
    .Clock {
        margin-bottom: scaleVW(40, 1440);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        @media screen and (max-width: 991px) {
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}